@import "bootstrap/dist/css/bootstrap.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS for the modal */
.modal {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.modal.open {
  display: block;
  opacity: 1;
  z-index: 1000;
}

.hclass {
  height: 50px;
  line-height: 50px;
  border: 1px solid #ccc !important;
}
.flex.min-h-full.flex-1.flex-col.justify-center.px-6.py-12.lg\:px-8 {
  padding: 0;
}
.flex.min-h-full.flex-1.flex-col.justify-center.px-6.py-12.lg\:px-8 {
  padding: 0;
}

label.block.text-sm.font-medium.leading-6.text-gray-900 {
  text-align: left;
}

.mt-2 {
  margin: 0 !important;
}

.flex.gap-5 {
  gap: 0rem !important;
  justify-content: space-between;
}
