.price-work .groth-bt {
  height: 40px;
  background: transparent;
  display: inline-block;
  margin: auto;
  text-align: center;
  line-height: 36px;
  padding: 0 32px;
  text-decoration: none;
}
.text-accent {
  font-family: "Bambino-Bold";
  font-size: 60px;
  line-height: 90px;
  color: #121212;
  text-align: center;
}

.fs-500 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #121212;
  margin: 20px 0 50px 0;
  text-align: center;
}

.mb {
  margin-bottom: 0.5em;
}
.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2em 0;
}
.plan {
  width: 16.5rem;
  padding: 2em;
  border-radius: 1em;
  margin: 0 0.5em 1em;
}
.plan--light {
  color: #4e4e4e;
  background: linear-gradient(-45deg, #e5e3e8, #fafafa);
}
.plan--light .plan-price {
  color: #035e30;
}
.plan--light .btn {
  color: #fff;
  background: #4e4e4e;
}
.plan--accent {
  color: #fff;
  background: linear-gradient(-45deg, #88b6b9, #035f30);
}
.plan--accent .btn {
  color: #4e4e4e;
  background: #fff;
}
.plan-title {
  text-transform: uppercase;
  margin: 0 0 1em;
}
.plan-price {
  margin: 0;
  font-size: 3rem;
  line-height: 1;
  font-weight: 900;
}
.plan-price span {
  display: block;
  font-size: 1.5625rem;
  font-weight: 300;
}
.plan-description {
  margin: 2em 0;
  line-height: 1.5;
}
.btn {
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background: #00a1ab;
  transition: 0.3s;
}
.btn:hover {
  opacity: 0.75;
}
.btn-mb {
  margin-bottom: 3em;
}
.btn--light {
  color: #4e4e4e;
  background: #fff;
}
.btn--dark {
  color: #fff;
  background: #4e4e4e;
}
.plan.plan--accent a.groth-bt.BT {
  border-color: #fff;
  color: #fff;
}
.plan-title {
  font-family: "Bambino-Bold";
  color: #000;
}
.plan.plan--accent h2.plan-title {
  color: #fff;
  font-family: "Bambino-Bold";
}
.plan-price {
  font-family: "Bambino-Bold";
}
.plan-description {
  font-family: "Bambino-Bold";
}
